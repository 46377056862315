<script>
import { ArrowUpIcon,ArrowRightIcon } from "vue-feather-icons";

import Navbar from "@/components/navbar";
import Footer from "@/components/footer";

/**
 * Index-developer component
 */
export default {
  components: {
    Navbar,
    Footer,
    ArrowUpIcon,
    ArrowRightIcon
  },
  data() {
    return {
    }
  }
}
</script>

<template>
  <div>
    <Navbar :nav-light="true"/>

        <!-- Hero Start -->
        <section class="bg-home bg-primary d-flex align-items-center" style="background: url('images/integration/bg.png') center center; height: auto;" id="home">
            <div class="container">
                <div class="row mt-5 justify-content-center">
                    <div class="col-lg-12 text-center mt-0 mt-md-5 pt-0 pt-md-5">
                        <div class="title-heading">
                            
                            <h4 class="heading text-white title-dark my-3">{{ $t("message.partner1") }}</h4>
                            <p class="para-desc mx-auto text-white-50">{{ $t("message.partner2") }}</p>
                            
                        </div>

                    </div><!--end col-->
                </div><!--end row-->
            </div><!--end container--> 
        </section><!--end section-->
 
        <!-- Hero End -->

        <!-- Start -->
        <section class="section">
       
            <div class="container mt-60">
                <div class="row">
                    <div class="col-lg-3 col-md-6 col-12">
                        <a href="javascript:void(0)">
                            <div class="media key-feature align-items-center p-3 rounded shadow">
                                <img src="images/suniv.svg" class="avatar avatar-ex-sm2" alt="">
                            </div>
                           
                        </a>
                    </div><!--end col-->
                    
                    <div class="col-lg-3 col-md-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <a href="http://www.yoomediaworks.com" target="_blank">
                            <div class="media key-feature align-items-center p-3 rounded shadow">
                                <img src="images/youme.svg" class="avatar avatar-ex-sm2" alt="">
                            </div>
                        </a>
                    </div><!--end col-->
                    
                    <div class="col-lg-3 col-md-6 col-12 mt-4 mt-lg-0 pt-2 pt-lg-0">
                        <a href="https://www.intelliquant.co.kr/" target="_blank">
                            <div class="media key-feature align-items-center p-3 rounded shadow">
                                <img src="images/intell.svg" class="avatar avatar-ex-sm2" alt="">
                            </div>
                        </a>
                    </div><!--end col-->
                    
                    <div class="col-lg-3 col-md-6 col-12 mt-4 mt-lg-0 pt-2 pt-lg-0">
                        <a href="http://www.kbdca.or.kr/" target="_blank">
                            <div class="media key-feature align-items-center p-3 rounded shadow">
                                <img src="images/cancer.png" class="avatar avatar-ex-sm2" alt="">
                            </div>
                        </a>
                    </div>
                </div>

            </div>
            <div class="container mt-5">

                <div class="row ">

                    <div class="col-lg-3 col-md-6 col-12 mt-4 mt-lg-0 pt-2 pt-lg-0">
                        <a href="https://minwho.kr" target="_blank">
                            <div class="media key-feature align-items-center p-3 rounded shadow">
                                <img src="images/minwoo.png" class="avatar avatar-ex-sm2" alt="">
                                
                            </div>
                        </a>
                    </div>

                    <div class="col-lg-3 col-md-6 col-12 mt-4 mt-lg-0 pt-2 pt-lg-0">
                        <a href="http://proptech.or.kr/" target="_blank">
                            <div class="media key-feature align-items-center p-3 rounded shadow">
                                <img src="images/prop2.svg" class="avatar  avatar-ex-sm2" alt="">
                                
                            </div>
                        </a>
                    </div>

                    <div class="col-lg-3 col-md-6 col-12 mt-4 mt-lg-0 pt-2 pt-lg-0">
                        <a href="http://zeeps.kr/" target="_blank">
                            <div class="media key-feature align-items-center p-3 rounded shadow">
                                <img src="images/zeeps_logo.svg" class="avatar  avatar-ex-sm2" alt="">
                                
                            </div>
                        </a>
                    </div>

                    <div class="col-lg-3 col-md-6 col-12 mt-4 mt-lg-0 pt-2 pt-lg-0">
                        <a href="http://www.maxpaperu.com/" target="_blank">
                            <div class="media key-feature align-items-center p-3 rounded shadow">
                                <img src="images/0_TlGoYOh1eoRTxGOO.png" class="avatar  avatar-ex-sm2" alt="">
                                
                            </div>
                        </a>
                    </div>

                </div><!--end row-->
            </div><!--end container-->

            

        </section><!--end section-->
        <!-- End -->


    <Footer />
    <!-- Footer End -->

    <!-- Back to top -->
    <a
      href="javascript: void(0);"
      class="btn btn-icon btn-primary back-to-top"
      id="back-to-top"
      v-scroll-to="'#topnav'"
    >
      <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
  </div>
</template>